<template>
  <b-card :id="id+`C`" body-class="p-3 d-flex flex-column justify-content-between">
    <b-card-title class="h5">
      {{ item.name }}
    </b-card-title>
    <b-card-text class="smaller">
      <b-form-row v-if="item.publicEmail" class="text-truncate pb-1">
        <b-col cols="1" md="2">
          <b-icon-at />
        </b-col>
        <b-col cols="11" md="10">
          <b-link :href="`mailto:`+item.publicEmail" v-text="item.publicEmail" />
        </b-col>
      </b-form-row>
      <b-form-row v-if="item.publicPhone" class="text-truncate">
        <b-col cols="2">
          <b-icon-phone />
        </b-col>
        <b-col cols="10">
          <b-link :href="`tel:`+item.publicPhone" v-text="item.publicPhone" />
        </b-col>
      </b-form-row>
      <hr v-if="item.publicEmail || item.publicPhone" class="pb-1 mt-2">
      <b-form-row v-if="item.street || item.number" class="text-truncate">
        <b-col cols="2">
          <b-icon-house />
        </b-col>
        <b-col cols="10">
          <div>{{ item | formatStreet }}</div>
        </b-col>
      </b-form-row>
      <b-form-row v-if="item.city || item.zip" class="text-truncate">
        <b-col cols="2">
          &nbsp;
        </b-col>
        <b-col cols="10">
          <div>{{ item | formatCity }}</div>
        </b-col>
      </b-form-row>
      <hr v-if="item.street || item.city" class="pb-1 mt-2">
      <b-form-row class="flex-nowrap text-nowrap text-center mt-2">
        <b-col>
          <b-button variant="primary" @click="$emit('clicked')"><Icon type="res" /> {{ $t('btn.res') }}</b-button>
        </b-col>
      </b-form-row>
    </b-card-text>
  </b-card>
</template>

<script>
import { vm } from '@/main';
import Icon from '@/components/Icon';
import { BIconHouse, BIconAt, BIconPhone } from 'bootstrap-vue';

export default {
  filters: {
    formatStreet(item) {
      if (vm.$store.state.oUser.formatId == 1) { // store not accessible, as filters are pure JS
        return item.street + ' ' + item.number;
      } else {
        return item.number + ' ' + item.street;
      }
    },
    formatCity(item) {
      if (vm.$store.state.oUser.formatId == 1) { // store not accessible, as filters are pure JS
        return item.zip + ' ' + item.city;
      } else {
        return item.city + ' ' + item.zip;
      }
    },
    // formatCountry(item) {
    //   if (vm.$store.state.oUser.formatId == 1) { // store not accessible, as filters are pure JS
    //     return item.countryId;
    //   } else {
    //     return item.stateId + item.countryId;
    //   }
    // }
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    }
  },
  components: {
    Icon,
    BIconHouse,
    BIconAt,
    BIconPhone,
  },
}
</script>

<style scoped>
.smaller {
  font-size:80%!important;
}
</style>
