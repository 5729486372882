<template>
  <div>
    <div class="mt-3">
      <h1 class="mb-2">
        {{ $t('lan.t') }}
      </h1>
      <b-row class="justify-content-center">
        <b-col cols="10">
          <Search id="landSearch" class="mt-1" @warn="onWarn" @action="onReserve" :mapText="$t('lan.mapt')" :warnText="$t('lan.wnor')" :labelText="$t('lan.search')" />
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col cols="10">
          <RestaurantVicinity @action="onReserve" />
        </b-col>
      </b-row>
    </div>

    <div v-show="hasTraData" class="mt-4">
      <h3 class="mb-2">
        {{ $t('nav.tra.t') }}
      </h3>
      <b-card header-tag="nav" id="landTra" body-class="p-2 p-sm-4">
        <template #header>
          <b-nav card-header tabs>
            <b-nav-item active link-classes="px-2 px-sm-3">{{ $t('lan.hto') }}</b-nav-item>
            <b-nav-item to="/transactions" link-classes="px-2 px-sm-3" exact exact-active-class="active"><Icon v-if="!isXs" type="tra" isNav :isButton="false" /> {{ $t('lan.hmng') }}</b-nav-item>
          </b-nav>
        </template>

        <TransTable ref="traTab" suffix="traTabUser"
            :query="traTabDef" @loaded="processTra"
            showShow showSubmit showPagination>
          <template #nextToPagi>
            <b-button variant="primary" class="float-right" to="/transactions"><Icon type="m" /> {{ $t('btn.m') }}</b-button>
          </template>
        </TransTable>
      </b-card>
    </div>

    <div class="mt-4">
      <h3 class="mb-2">
        {{ $t('nav.acc.fav') }}
      </h3>
      <b-card header-tag="nav" id="landFav" body-class="p-2 p-sm-4">
        <template #header>
          <b-nav card-header tabs>
            <b-nav-item active link-classes="px-2 px-sm-3">{{ $t('lan.hovw') }}</b-nav-item>
            <b-nav-item to="/account/favorites" link-classes="px-2 px-sm-3" exact exact-active-class="active"><Icon v-if="!isXs" type="fav" isNav :isButton="false" /> {{ $t('lan.hmng') }}</b-nav-item>
          </b-nav>
        </template>

        <UserFavoritesTable v-if="hasFavData" :query="favQueryDef" showPagination @loaded="processFav"
            :btnShow="true" btnIdPrefix="favTab" btnVariant="primary" :btnClick="onReserveFav">
          <template #buttons>
            <span class="text-nowrap"><Icon type="res" /> {{ $t('btn.res') }}</span>
          </template>
          <template #nextToPagi>
            <b-button variant="primary" class="float-right" v-if="showFavMore" to="/account/favorites"><Icon type="m" /> {{ $t('btn.m') }}</b-button>
          </template>
        </UserFavoritesTable>
        <b-card v-else>
          <b-link to="/account/favorites">{{ $t('lan.fno') }}</b-link>
        </b-card>
      </b-card>
    </div>

    <div class="mt-4">
      <h3 class="mb-2">
        {{ $t('nav.res.t') }}
      </h3>
      <b-card header-tag="nav" id="landRes" body-class="p-2 p-sm-4">
        <template #header>
          <b-nav card-header tabs>
            <b-nav-item active to="/reservations/next" link-classes="px-2 px-sm-3" exact exact-active-class="active">{{ $t('lan.hnxt') }}</b-nav-item>
            <b-nav-item to="/reservations" link-classes="px-2 px-sm-3" exact exact-active-class="active"><Icon v-if="!isXs" type="res" isNav :isButton="false" /> {{ $t('lan.hmng') }}</b-nav-item>
          </b-nav>
        </template>

        <ReservationsUserTable :query="resTabDef" suffix="resTabOvwU"
            noHitLink="/reservations/new" :noHitText="$t('lan.rno')" showShow showPagination>
          <template #nextToPagi>
            <b-button variant="primary" class="float-right" to="/reservations/next"><Icon type="m" /> {{ $t('btn.m') }}</b-button>
          </template>
        </ReservationsUserTable>
      </b-card>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import Search from '@/components/shared/Search';
import UserFavoritesTable from '@/components/account/UserFavoritesTable';
import ReservationsUserTable from '@/components/reservations/UserTable';
import TransTable from '@/components/transactions/TransTable';
import RestaurantVicinity from '@/components/landing/RestaurantVicinity';

export default {
  data() {
    return {
      hasFavData: true,
      showFavMore: false,
      favQueryDef: { p: '3' },
      resTabDef: { q: 'startAt:ge:{T}', s: '+startAt', p: '3' },
      hasTraData: false,
      traTabDef: { q: 'state::PREPARED', s: '-createdAt', p: '3' },
    }
  },
  computed: {
    isXs() {
      return this.$store.getters.isXs;
    },
  },
  methods: {
    onReserveFav(custObj) {
      custObj.locationCurrency = custObj.customerCurrency; // rewrite property >> non-reactive!
      this.$store.commit('setNewRes', custObj);
      this.$router.push('/reservations/new');
    },
    onReserve(custObj) {
      // careful here! custObj is now completely non-reactive! but it should not be necessary
      custObj = { customerUuid4: custObj.customerUuid4, customerName: custObj.name, customerCity: custObj.city, locationCurrency: custObj.locationCurrency, resAmount: custObj.resAmount };
      this.$store.commit('setNewRes', custObj);
      this.$router.push('/reservations/new');
    },
    onWarn(text) {
      this.$store.commit('showWarn',text);
    },
    processFav(res) {
      this.hasFavData = res.hasContent;
      this.showFavMore = res.hasMore;
    },
    processTra(res) {
      this.hasTraData = res.hasContent;
    },
  },
  components: {
    Search,
    RestaurantVicinity,
    UserFavoritesTable,
    ReservationsUserTable,
    TransTable,
    Icon,
  },
}
</script>
