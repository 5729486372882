<template>
  <div v-if="!geo.err && data">
    <p class="mb-2">{{ $t('lan.resVic') }}</p>
    <b-card-group>
      <RestaurantDetails v-for="(item, idx) in data.slice(0, (isXs||isSm?2:3))" :key="`lanRestC`+idx" :id="`lanRestC`+idx" :item="item" @clicked="onClick(idx)" />
    </b-card-group>
  </div>
</template>

<script>
import RestaurantDetails from '@/components/landing/RestaurantDetails';
import { REST } from '@/components/RestCall';

export default {
  data() {
    return {
      data: null,
      geo: {
        gettingLocation: false,
        err: false,
      },
    };
  },
  mounted() {
    this.data = null;
    this.geo.err = !this.geoLocationAvailable;
    if (!this.geo.err) {
      this.geo.gettingLocation = true;
      navigator.geolocation.getCurrentPosition(async pos => {
        this.geo.gettingLocation = false;
        this.vicinityFetch([pos.coords.latitude.toFixed(5), pos.coords.longitude.toFixed(5)]);
      }, err => {
        this.geo.err = true;
        this.$store.commit('showWarn', err.message);
      });
    }
    // this.data = [
    //   {uuid4: 'sth', name: 'Zum Schwan', resAmount: 0, locationCurrency: 'EUR', locationTimezone: 'Europe/Berlin', publicEmail: 'test1@example.com', publicPhone: null, street: 'MainSt', number: '1a', city: 'Berlin', zip: '12345', whMoS: '10:00', whMoE: '00:00'},
    //   {uuid4: 'sth', name: 'LooooooooooooooooooooongNaaaaame', resAmount: 0, locationCurrency: 'EUR', locationTimezone: 'Europe/Berlin', publicEmail: 'test3@example.com', publicPhone: null, street: 'Straße', number: '100', city: 'Berlin', zip: '33333', whMoS: '10:00', whMoE: '00:00'},
    //   {uuid4: 'sth', name: 'Restaurant 東京', resAmount: 10000, locationCurrency: 'EUR', locationTimezone: 'Europe/Berlin', publicEmail: 'test2@example.com', publicPhone: '+49123456789', street: 'MainSt', number: '1', city: '東京', zip: '123456', whMoS: '10:00', whMoE: '00:00'},
    // ];
  },
  computed: {
    geoLocationAvailable() {
      return ('geolocation' in navigator);
    },
    isXs() {
      return this.$store.getters.isXs;
    },
    isSm() {
      return this.$store.getters.isSm;
    },
    isMd() {
      return this.$store.getters.isMd;
    },
    isLg() {
      return this.$store.getters.isLg;
    },
  },
  methods: {
    onClick(idx) {
      let custObj = this.data[idx];
      custObj = { customerUuid4: custObj.uuid4, name: custObj.name, city: custObj.city, locationCurrency: custObj.locationCurrency, resAmount: custObj.resAmount };
      this.$emit('action', custObj);
    },
    vicinityFetch(arr) {
      REST.get('/restaurants/5000/' + arr[0] + '/' + arr[1]/*, { params: {p: 3} }*/ // qry params currently not supported
        ).then(resp => {
          this.data = resp.d.data;
        }).catch(e => {
          this.data = null;
          this.$emit('showWarn', e.message);
        });
      }
  },
  components: {
    RestaurantDetails,
  },
}
</script>
