import { render, staticRenderFns } from "./RestaurantDetails.vue?vue&type=template&id=2f2f0ef5&scoped=true&"
import script from "./RestaurantDetails.vue?vue&type=script&lang=js&"
export * from "./RestaurantDetails.vue?vue&type=script&lang=js&"
import style0 from "./RestaurantDetails.vue?vue&type=style&index=0&id=2f2f0ef5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f2f0ef5",
  null
  
)

export default component.exports